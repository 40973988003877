import { Button, Card, Affix, Modal, Row, Col } from "antd";
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../App";
import { ArrowRightOutlined, MessageOutlined } from "@ant-design/icons";
import logo from "../../assets/images/logozivo.png";
import minLogo from "../../assets/images/logo-blanco.png";

const SiderRight = () => {
  const location = useLocation();

  const { state } = useContext(AppContext);

  return (
    <Affix offsetTop={110}>
      <Row
        style={{
          backgroundColor: "#1F3461",
          borderRadius: "20px",
          minHeight: "85vh",
          paddingTop: "10px",
          marginLeft: "2px",
        }}
        align={"space-around"}
      >
        <Row>
          <Col span={24}>
            <center>
              <img src={logo} width="50px" style={{ marginTop: "10px" }} />
            </center>
          </Col>
          <Col span={24} style={{ minHeight: "300px" }}>
            <Row align={"top"}>
              <Col
                span={24}
                style={{
                  backgroundColor:
                    location.pathname == "/" ? "white" : "#1F3461",
                }}
              >
                <Link to="/">
                  <Button
                    type="link"
                    style={{
                      color: location.pathname !== "/" ? "white" : "#1F3461",
                    }}
                  >
                    Mapa
                  </Button>
                </Link>
              </Col>
              <Col
                span={24}
                style={{
                  backgroundColor:
                    location.pathname == "/dga" ? "white" : "#1F3461",
                }}
              >
                <Link to="/dga">
                  <Button
                    type="link"
                    style={{
                      color: location.pathname !== "/dga" ? "white" : "#1F3461",
                    }}
                  >
                    Dga
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <center>
              <img src={minLogo} width={"130px"} />
            </center>
          </Col>
        </Row>
      </Row>
    </Affix>
  );
};

export default SiderRight;
