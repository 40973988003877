import React, { useState, useContext } from "react";
import { Row, Col, Table, Typography, Badge, Button } from "antd";
import { UnorderedListOutlined, EyeFilled } from "@ant-design/icons";
import { TileLayer, MapContainer, Marker, Popup } from "react-leaflet";
import { AppContext } from "../../App";
import L from "leaflet";

const MapList = () => {
  const { dispatch } = useContext(AppContext);
  const communesCoordinates = [
    {
      lat: -37.33,
      lon: -71.83,
      name: "PC 1",
      province: "Biobio",
      region: "Biobio",
      status: true,
      flow_dga: 10.8,
      cubics_dga: 1000,
      depth: 10,
      nivel: 5,
      f_nivel: 2.0,
      flow: 5.1,
      total: 455,
      last_date: "10-10-2024 12:00 hrs",
    },
    {
      lat: -37.5,
      lon: -72.53,
      name: "PC 2",
      province: "Biobio",
      region: "Biobio",
      status: true,
      flow_dga: 6.8,
      cubics_dga: 345611,
      depth: 30,
      nivel: 4,
      f_nivel: 1.0,
      flow: 3.1,
      total: 42255,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -36.97,
      lon: -72.93,
      name: "PC 3",
      province: "Concepción",
      region: "Biobio",
      status: true,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -36.97,
      lon: -72.93,
      name: "PC 4",
      province: "Concepción",
      region: "Biobio",
      status: true,
      flow_dga: parseFloat(Math.random() * 10).toFixed(1),
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -37.62,
      lon: -73.38,
      name: "PC 5",
      province: "Arauco",
      region: "Biobio",
      status: false,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -37.35,
      lon: -71.83,
      name: "PC 6",
      province: "Biobio",
      region: "Biobio",
      status: true,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -36.74,
      lon: -72.3,
      name: "PC 7",
      province: "Diguillin",
      region: "Ñuble",
      status: false,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -36.42,
      lon: -71.95,
      name: "PC 8",
      province: "Diguillin",
      region: "Ñuble",
      status: true,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -37.8,
      lon: -73.33,
      name: "PC 9",
      province: "Arauco",
      region: "Ñuble",
      status: true,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -37.12,
      lon: -72.55,
      name: "PC 10",
      province: "Diguillin",
      region: "Ñuble",
      status: false,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -36.74,
      lon: -72.42,
      name: "PC 11",
      province: "Diguillin",
      region: "Ñuble",
      status: true,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -36.62,
      lon: -72.95,
      name: "PC 12",
      province: "Diguillin",
      region: "Ñuble",
      status: false,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -36.8,
      lon: -72.95,
      name: "PC 13",
      province: "Diguillin",
      region: "Ñuble",
      status: true,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -36.62,
      lon: -72.05,
      name: "PC 14",
      province: "Diguillin",
      region: "Ñuble",
      status: true,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -37.58,
      lon: -72.53,
      name: "PC 15",
      province: "Diguillin",
      region: "Ñuble",
      status: false,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -36.73,
      lon: -71.83,
      name: "PC 16",
      province: "Diguillin",
      region: "Ñuble",
      status: true,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -36.28,
      lon: -72.53,
      name: "PC 17",
      province: "Diguillin",
      region: "Ñuble",
      status: true,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
    {
      lat: -36.62,
      lon: -72.95,
      name: "PC 18",
      province: "Diguillin",
      region: "Ñuble",
      status: false,
      flow_dga: Math.random() * 10,
      cubics_dga: Math.random() * 1000,
      depth: Math.random() * 100,
      nivel: Math.random() * 10,
      f_nivel: Math.random() * 5,
      flow: Math.random() * 10,
      total: Math.random() * 1000,
      last_date: "11-10-2024 13:00 hrs",
    },
  ];

  const allCoordinates = [...communesCoordinates];
  const [selectedCoordinates, setSelectedCoordinates] =
    useState(allCoordinates);
  return (
    <Row justify={"center"} align={"middle"}>
      <Col span={16} style={{ paddingRight: "10px" }}>
        <MapContainer
          center={[
            selectedCoordinates[0]?.lat,
            selectedCoordinates[selectedCoordinates.length - 1]?.lon,
          ]}
          zoom={8}
          scrollWheelZoom={false}
          style={styles.mapContaier}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {selectedCoordinates.map((coordinate, index) => {
            if (!coordinate.lat || !coordinate.lon) {
              return null;
            }
            // Generate a random color
            const randomColor = `#${Math.floor(
              Math.random() * 16777215
            ).toString(16)}`;
            return (
              <Marker
                key={index}
                position={[coordinate.lat, coordinate.lon]}
                title={coordinate.name}
                icon={L.icon({
                  iconUrl: coordinate.status
                    ? "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png"
                    : "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                  popupAnchor: [1, -34],
                  shadowSize: [41, 41],
                })}
              >
                <Popup>
                  <Row>
                    <Col>
                      <Badge
                        status={coordinate.status ? "processing" : "error"}
                      />{" "}
                      {"  "}
                      <b>{coordinate.name}</b>
                    </Col>
                    <Col style={{ marginTop: "6px" }}>
                      {coordinate.last_date}
                      <hr />
                      Nivel: {parseFloat(coordinate.f_nivel).toFixed(1)} m{" "}
                    </Col>
                    <Col>
                      Caudal: {parseFloat(coordinate.flow).toFixed(1)} l/s
                    </Col>
                    <Col>Total: {parseInt(coordinate.total)} m³</Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>lat:{coordinate.lat}</Col>

                    <Col>lon:{coordinate.lon}</Col>
                  </Row>
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>
      </Col>
      <Col span={8}>
        <Table
          bordered
          rowHoverable
          size={"small"}
          dataSource={selectedCoordinates}
          columns={[
            {
              title: "Nombre",
              render: (x) => (
                <>
                  {x.name}{" "}
                  {x.status ? (
                    <Badge status={"processing"} />
                  ) : (
                    <Badge status={"error"} />
                  )}
                </>
              ),
            },
            {
              title: "Provincia",
              render: (record) => <>{record.province}</>,
            },
            {
              render: (x) => (
                <Button
                  size={"small"}
                  type={"primary"}
                  icon={<EyeFilled />}
                  onClick={() =>
                    dispatch({
                      type: "CHANGE_SELECTED_PROFILE",
                      payload: {
                        selected_profile: x,
                      },
                    })
                  }
                >
                  Ver
                </Button>
              ),
            },
          ]}
          title={() => (
            <Row justify={"space-evenly"}>
              <Col>
                <UnorderedListOutlined style={{ fontSize: "20px" }} />
              </Col>
              <Col>
                <Typography.Text style={{ fontWeight: "600" }}>
                  Puntos de captación
                </Typography.Text>
              </Col>
            </Row>
          )}
        />
      </Col>
    </Row>
  );
};

const styles = {
  mapContaier: {
    height: "550px",
    width: "100%",
    zIndex: 0,
    marginTop: "20px",
    borderRadius: "20px",
  },
};

export default MapList;
