import React, { useContext } from "react";
import { AppContext } from "../../App";
import {
  Typography,
  Card,
  Input,
  Modal,
  Row,
  Col,
  Button,
  notification,
} from "antd";
import { useLocation } from "react-router-dom";
import { MailOutlined, AlertFilled } from "@ant-design/icons";

const { Title } = Typography;

const SiderLeft = () => {
  const { state } = useContext(AppContext);
  let location = useLocation();

  return (
    <>
      <Card style={styles.card}>
        <Title align="center" style={styles.title} level={4}>
          <p
            style={{ color: "white", textAlign: "center", marginTop: "-12px" }}
          >
            {state.selected_profile
              ? state.selected_profile.name
              : "Selecciona un punto de captación"}
          </p>
        </Title>
        <Row align={"middle"} justify={"center"}>
          <Col span={24}>
            <div style={styles.element}>
              Caudal otorgado por la DGA:
              <br />
              <b>
                <Typography.Paragraph style={{ fontSize: "16px" }}>
                  {state.selected_profile ? (
                    <>
                      {parseFloat(state.selected_profile.flow_dga).toFixed(1)}{" "}
                      lt/s
                    </>
                  ) : (
                    "-"
                  )}
                </Typography.Paragraph>
              </b>
            </div>
          </Col>

          <Col span={24}>
            <div style={styles.element}>
              m³ anuales otorgado por la DGA:
              <br />
              <b>
                <Typography.Paragraph style={{ fontSize: "16px" }}>
                  {state.selected_profile ? (
                    <>{parseInt(state.selected_profile.cubics_dga)} m³</>
                  ) : (
                    "-"
                  )}
                </Typography.Paragraph>
              </b>
            </div>
          </Col>

          <Col span={24}>
            <div style={styles.element}>
              Profundidad:
              <br />
              <b>
                <Typography.Paragraph style={{ fontSize: "16px" }}>
                  {state.selected_profile ? (
                    <>{parseInt(state.selected_profile.depth)} m</>
                  ) : (
                    "-"
                  )}
                </Typography.Paragraph>
              </b>
            </div>
          </Col>

          <Col span={24}>
            <div style={styles.element}>
              Posicionamiento de sensor:
              <br />
              <b>
                <Typography.Paragraph style={{ fontSize: "16px" }}>
                  {state.selected_profile ? (
                    <>{parseFloat(state.selected_profile.nivel).toFixed(1)} m</>
                  ) : (
                    "-"
                  )}
                </Typography.Paragraph>
              </b>
            </div>
          </Col>
          <Col>
            <h3 style={{ color: "white", textAlign: "center" }}>
              Última medición
              <br />
              <h5 style={{ marginTop: "-5px" }}>
                {state.selected_profile
                  ? state.selected_profile.last_date
                  : "MM-DD-YYYY hh:mm"}
              </h5>
            </h3>
          </Col>

          <Col span={24}>
            <div style={styles.element}>
              Nivel Freático:
              <br />
              <b>
                <Typography.Paragraph style={{ fontSize: "16px" }}>
                  {state.selected_profile ? (
                    <>
                      {parseFloat(state.selected_profile.f_nivel).toFixed(1)} m
                    </>
                  ) : (
                    "-"
                  )}
                </Typography.Paragraph>
              </b>
            </div>
          </Col>

          <Col span={24}>
            <div style={styles.element}>
              Caudal:
              <br />
              <b>
                <Typography.Paragraph style={{ fontSize: "16px" }}>
                  {state.selected_profile ? (
                    <>
                      {parseFloat(state.selected_profile.flow).toFixed(1)} l/s
                    </>
                  ) : (
                    "-"
                  )}
                </Typography.Paragraph>
              </b>
            </div>
          </Col>
          <Col span={24}>
            <div style={styles.element}>
              Acumulado:
              <br />
              <b>
                <Typography.Paragraph style={{ fontSize: "16px" }}>
                  {state.selected_profile ? (
                    <>{parseInt(state.selected_profile.total)} m³</>
                  ) : (
                    "-"
                  )}
                </Typography.Paragraph>
              </b>
            </div>
          </Col>
          <Col>
            <Button
              type={"primary"}
              icon={<AlertFilled />}
              danger
              onClick={() => {
                Modal.error({
                  title: "Alerta de incidencia",
                  onOk: () => {
                    notification.success({
                      message: "Incidencia alertada",
                      description: "Se ha alertado la incidencia correctamente",
                    });
                  },
                  content: (
                    <>
                      <Input.TextArea
                        placeholder="Ingrese la descripción de la incidencia"
                        rows={5}
                      />
                    </>
                  ),
                });
              }}
            >
              Alertar incidencia
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

const styles = {
  card: {
    backgroundColor: "#1F3461",
    borderRadius: "20px",
    minHeight: "85vh",
  },
  title: {
    color: "white",
    marginTop: "-15px",
    marginBottom: "30px",
  },
  element: {
    textAlign: "center",
    paddingLeft: "4px",
    paddingRight: "4px",
    backgroundColor: "white",
    borderRadius: "10px",
    marginLeft: "-20px",
    marginRight: "-20px",
  },
};

export default SiderLeft;
